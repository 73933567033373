<template>
  <div class="curriculum-free">
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <video
      :src="
        courseData.chapterVOList && courseData.chapterVOList[0].chapterVideo
          ? courseData.chapterVOList[0].chapterVideo
          : ''
      "
      controls
      poster=""
      preload
      ref="refVideo"
      id="refVideo"
      controlslist="nodownload"
      x-webkit-airplay="allow"
      webkit-playsinline="true"
      x5-playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="false"
      playsinline="true"
      preload="auto"
      x5-video-orientation="h5"
      autoplay
    >
      浏览器不支持播发该视频
    </video>

    <div class="curriculum-details">
      <div class="left">
        <div class="info">
          <div class="name">限时优惠</div>
          <div>{{ courseData.courseName }}</div>
        </div>
        <div class="number">
          学习次数<span>{{ courseData.studyCount }}</span> 课时数<span>{{
            courseData.chapterCount
          }}</span>
        </div>
      </div>
      <div class="right">
        <img @click="maskShow = true" src="../../assets/20g.gif" alt="" />
      </div>
    </div>
    <div class="catalogue">
      <div class="title">目录</div>
      <div :class="openCatalogueFlag ? 'open-catalogue-box' : 'catalogue-box'">
        <div
          v-for="(item, index) in courseData.chapterVOList"
          class="catalogue-item"
          @click="maskShow = true"
        >
          <div class="catalogue-name">
            <div v-if="item.chapterType == 1" class="label">试听</div>
            <div v-else class="lock">
              <img src="../../assets/lock.svg" alt="" />
            </div>
            <div class="name">{{ index + 1 }}.{{ item.chapterName }}</div>
          </div>
          <div class="catalogue-time">{{ item.chapterTime }}</div>
        </div>
        <div
          class="btm"
          v-if="courseData.chapterVOList.length > 3"
          @click="openCatalogue"
        >
          <img v-if="!openCatalogueFlag" src="../../assets/btm.svg" alt="" />
          <img v-else src="../../assets/top.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="footer">
      <img src="../../assets/vip.svg" alt="" />
    </div>
    <div class="mask-box pos-fiexd" v-show="maskShow">
      <!-- <div class="mask"></div> -->
      <div class="mask-content p-a-c bg-fff">
        <div class="close text-right">
          <img
            src="@/assets/close.png"
            alt=""
            style="width: 14px; height: 14px"
            @click="changeMask"
          />
        </div>
        <div class="content text-center">
          <div class="text">下载洋葱韩语app</div>
          <div class="btn" @click="download">
            <span>去下载</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { getCourseInfo } from "../../api/curriculum";
export default {
  mixins: [mixin],
  name: "curriculumFree",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      maskShow: false,
      courseData: {},
      openCatalogueFlag: false,
    };
  },
  created() {
    this.getCourseInfo();
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    // 高度
    getHeight() {
      let video = document.getElementById("refVideo");
      // video.style.height= (document.documentElement.clientWidth * 9) / 16+"px";
    },
    openCatalogue() {
      this.openCatalogueFlag = !this.openCatalogueFlag;
    },
    changeMask() {
      this.maskShow = !this.maskShow;
    },
    getCourseInfo() {
      let params = {
        courseId: this.$route.query.id || "1",
      };
      getCourseInfo(params).then((res) => {
        if (res.code !== "SUCCESS") return;
        this.courseData = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.curriculum-free {
  width: 100vw;
}
video {
  margin: 37px 0 0;
  width: 100%;
  object-fit: cover;
}
.curriculum-details {
  display: flex;
  position: relative;
  .left {
    padding: 16px;
    box-sizing: border-box;
    .info {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 18px;
      font-weight: 550;
    }
    .name {
      margin-right: 12px;
      width: 59px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background: #fe646e;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      font-weight: 500;
    }
    .number {
      margin: 13px 0 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      span {
        margin: 0 20px 0 8px;
      }
    }
  }
  .right {
    position: fixed;
    right: 1px;
    top: 330px;
    z-index: 9;
    img {
      display: block;
      width: 117px;
    }
  }
}
.catalogue {
  position: relative;
  padding: 16px 16px 24px;
  box-sizing: border-box;
  background: #f4f8ff;
  .title {
    font-size: 18px;
    color: #333333;
  }
  .catalogue-box {
    margin: 16px 0 0;
    padding: 0 16px;
    height: 158px;
    overflow: hidden;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
  }
  .open-catalogue-box {
    margin: 16px 0 0;
    padding: 0 16px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
  }
  .btm {
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .catalogue-item {
    padding: 14px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f8faff;
    &:last-child {
      border: 1px solid transparent;
    }
    .catalogue-name {
      display: flex;
      align-items: center;
      height: 20px;
    }
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 18px;
      font-size: 12px;
      color: #78bbfb;
      background: #e5f0ff;
      border: 1px solid #78bbfb;
      border-radius: 4px;
    }
    .lock {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      img {
        // display: block;
      }
    }
    .name {
      margin-left: 9px;
      font-size: 16px;
      color: #333333;
    }
    .catalogue-time {
      font-size: 14px;
      color: #666666;
    }
  }
}
.footer {
  img {
    display: block;
    width: 100vw;
  }
}
</style>
